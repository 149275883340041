import ShopFrom from "../components/BuySell/ShopFrom/ShopFrom.vue";
import Negotiation from "../components/BuySell/Negotiation/Main.vue";
import FindOffer from "../components/BuySell/FindOffer/Main.vue";

export default [
  {
    path: "/",
    name: "HomePage",
    component: () => import("../components/HomePage/Main.vue"),
  },
  // Auth
  {
    path: "/register",
    name: "Register",
    component: () => import("../components/Auth/Forms.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../components/Auth/Forms.vue"),
  },
  {
    path: "/forgetpass",
    name: "ForgetPass",
    component: () => import("../components/Auth/Forms.vue"),
  },
  {
    path: "/otpforget",
    name: "OtpForget",
    component: () => import("../components/Auth/Forms.vue"),
  },
  {
    path: "/newpass",
    name: "NewPass",
    component: () => import("../components/Auth/Forms.vue"),
  },
  {
    path: "/otpform",
    name: "OtpForm",
    component: () => import("../components/Auth/Forms.vue"),
  },
  //public pages
  {
    path: "/aboutus",
    name: "AboutUs",
    component: () => import("../components/PublicPages/AboutUs/Main.vue"),
  },
  {
    path: "/faq",
    name: "FAQ",
    component: () => import("../components/PublicPages/FAQ.vue"),
  },
  {
    path: "/contactus",
    name: "ContactUs",
    component: () => import("../components/PublicPages/ContactUs.vue"),
  },
  {
    path: "/panel",
    name: "Panel",
    component: () => import("../components/Panel/Main.vue"),
    children: [
      {
        path: "/user",
        name: "User",
        component: () => import("../components/Panel/Profile/Main.vue"),
      },
      {
        path: "validation",
        name: "Validation",
        component: () => import("../components/Panel/Profile/Validation.vue"),
      },
      {
        path: "profile",
        name: "Profile",
        component: () => import("../components/Panel/Profile/Main.vue"),
      },
      {
        path: "history",
        name: "History",
        component: () => import("../components/Panel/History/Main.vue"),
      },
      {
        path: "settings",
        name: "Settings",
        component: () => import("../components/Panel/Settings/Main.vue"),
      },
      {
        path: "chats",
        name: "Chats",
        component: () => import("../components/Panel/Chats/Main.vue"),
      },
      {
        path: "notifications",
        name: "Notifications",
        component: () => import("../components/Panel/Notifications/Main.vue"),
      },
      {
        path: "wallet",
        name: "Wallet",
        component: () => import("../components/Panel/Wallet/Main.vue"),
      },
      {
        path: "dashboard",
        name: "Dashboard",
        component: () => import("../components/Panel/Dashboard/Main.vue"),
      },
    ],
  },
  {
    path: "/negotiation",
    name: "Negotiation",
    component: Negotiation,
    children: [
      {
        path: "",
        redirect: "/negotiation/buysubmit",
      },
      {
        path: "buysubmit",
        name: "BuySubmit",
        component: Negotiation,
      },
      {
        path: "buytimer",
        name: "BuyTimer",
        component: Negotiation,
      },
      {
        path: "buysubmited",
        name: "BuySubmited",
        component: Negotiation,
      },
      {
        path: "sellsubmit",
        name: "SellSubmit",
        component: Negotiation,
      },
      {
        path: "selltimer",
        name: "SellTimer",
        component: Negotiation,
      },
      {
        path: "sellsubmited",
        name: "SellSubmited",
        component: Negotiation,
      },
    ],
  },
  {
    path: "/buyfrom",
    name: "BuyFrom",
    component: ShopFrom,
    props: true,
  },
  {
    path: "/sellfrom",
    name: "SellFrom",
    component: ShopFrom,
    props: true,
  },
  {
    path: "/findoffersell",
    name: "FindSell",
    component: FindOffer,
  },
  {
    path: "/findofferbuy",
    name: "FindBuy",
    component: FindOffer,
  },
  {
    path: "/createoffer",
    name: "CreateOffer",
    component: () => import("../components/CreateOffer/Main.vue"),
  },
];
