<template>
  <div class="mini-container columnd gap32">
    <h2 class="f18-700 c-darkBlue text-start">About this offer</h2>
    <div class="columnd gap24">
      <div class="rowd space-between">
        <span class="f14-400 c-black"
          >{{ $route.path.includes("buy") ? "Seller" : "Buyer" }} rate</span
        >
        <div class="rowd gap4">
          <span class="f16-700 c-black">18,290.74 EUR</span>
          <inline-svg
            class="fill-lCryan"
            :src="require('../../../assets/Icons/ArrowUp.svg')"
          />
          <span class="f14-700 c-lightCryan">1.2%</span>
          <span class="f14-400 c-lightCryan">above market</span>
        </div>
      </div>
      <div class="rowd space-between">
        <span class="f14-400 c-black"
          >{{ $route.path.includes("buy") ? "Buy" : "Sell" }} limits</span
        >
        <div class="rowd gap4">
          <span class="f16-700 c-medGrey">Min</span>
          <span class="f16-700 c-black"> 100 EUR</span>
          <span class="f16-700 c-medGrey">Max</span>
          <span class="f16-700 c-black">1000 EUR</span>
        </div>
      </div>
      <div class="rowd space-between">
        <span class="f14-400 c-black">Trade time limit</span>
        <span class="f16-700 c-black">5h </span>
      </div>
      <div class="rowd space-between">
        <span class="f14-400 c-black">Radin fee</span>
        <span class="f16-700 c-black">0</span>
      </div>
    </div>
    <div class="columnd gap24">
      <h3 class="f14-700 c-ddGrey text-start">Tags</h3>
      <div class="tags rowd gap8">
        <span class="f12-400 c-darkGrey"> No receipt needed </span>
        <span class="f12-400 c-darkGrey"> No verification needed </span>
        <span class="f12-400 c-darkGrey"> Trusted user </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OfferInfo",
};
</script>

<style lang="scss" scoped>
.mini-container {
  padding: 48px;
}
</style>
