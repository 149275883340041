import { createStore } from "vuex";

export default createStore({
  state: {
    userInfo: {
      firstName: "Ali",
      lastName: "Rjbzdeh",
      fullName: "",
      address: "",
      email: "",
      nationalCode: "",
      mobileNumber: "",
      bankAccounts: [],
      remember: false,
      towStepAuthenticationType: "",
      authenticationType: "",
      authorized: false,
      pending: false,
      supervisor: false,
      userAccountLevel: "",
      totalTradesVolume: 0,
      fee: 0.4,
      image: "",
    },
    token: "",
    step: 1,
  },
  getters: {
    getStep(state) {
      return state.step;
    },
  },
  mutations: {
    stepUp(state) {
      state.step++;
    },
    stepDown(state) {
      state.step--;
    },
    setUserInfo(state, obj) {
      let key = Object.keys(obj);
      let value = Object.values(obj);
      key.map((item, index) => {
        state.userInfo[item] = value[index];
      });
    },
  },
  actions: {},
  modules: {},
});
