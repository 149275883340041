<template>
  <div class="modal-backdrop" @click.self="close">
    <div class="modal">
      <inline-svg
        class="fill-black align-self-end svg14"
        @click="close"
        :src="require('../../../assets/Icons/close.svg')"
      />
      <div class="columnd gap32">
        <h2>Have you paid and followed instractions?</h2>
        <div class="columnd gap8">
          <p class="f12-700">
            i confirm that H have read the indtructions and send vendir payment
            as they requsted.If yoy are unsure what to do please ask the vendor
            in the chat.
          </p>
          <p class="c-black f700">
            Clicking confirm before making payment to thevendor will get you
            bloked from this vendor
          </p>
        </div>
        <h2>Instruction</h2>
        <p>
          <span class="f14-700">1-</span> Upload a picture of your Full screen
          showing tht the founds are already avalable in yoyr PayPal balance and
          it is VERIFIED. Remember that you must show your FULL screen!
        </p>
        <p><span class="f14-700">2-</span>Give me your PayPal Email</p>
        <div class="rowd gap8 w100 justify-end">
          <button class="secondary-btn w100">I didn’t pay</button>
          <button @click.prevent="reD" class="primary-btn w100">I paid</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConfirmModal",
  methods: {
    close() {
      this.$emit("close");
    },
    reD() {
      this.$route.path.includes("buy")
        ? this.$router.push({ name: "BuyTimer" })
        : this.$router.push({ name: "SellTimer" });
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.f700 {
  font-weight: 700 !important;
}
.c-black {
  color: var(--black) !important;
}
</style>
