<template>
  <div class="ham-container">
    <div @click="ham = !ham" class="ham-btn columnd">
      <span :class="{ top: ham }"></span>
      <span :class="{ hide: ham }"></span>
      <span :class="{ bottom: ham }"></span>
    </div>
    <transition name="ham">
      <div v-if="ham" class="item-container columnd">
        <a
          v-for="(item, index) in links"
          :key="index"
          @click.prevent=""
          href=""
          class="primary-a f14-400"
        >
          {{ item.name }}
        </a>
        <button v-if="!$store.state.token" class="secondary-btn f14-700">
          Register
        </button>
        <button v-if="!$store.state.token" class="primary-btn f14-700">
          Login
        </button>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "HamberMenu",
  props: ["links"],
  data() {
    return {
      ham: false,
    };
  },
  methods: {
    clickOut(e) {
      if (!e.target.closest(".ham-btn")) {
        this.ham = false;
      }
    },
  },
  beforeUnmount() {
    window.removeEventListener("click", this.clickOut);
  },
  created() {
    window.addEventListener("click", this.clickOut);
  },
};
</script>

<style lang="scss" scoped>
.secondary-btn {
  margin-top: 50px;
}
.item-container {
  padding: 80px 20px;
  gap: 16px;
  position: fixed;
  width: max-content;
  height: 100%;
  background: var(--llGrey);
  left: 0;
  top: 0;
  border-radius: 0px 8px 0px 8px;
  align-items: flex-start;
}
.top {
  transform: rotate(45deg) translate(3px, 6px) !important;
}
.bottom {
  transform: rotate(-45deg) translate(-2px, -1px) !important;
}
.ham-btn {
  gap: 3px;
  span {
    width: 20px;
    height: 3px;
    background: var(--darkCryan);
    border-radius: 8px;
    transform: translate(0, 0) rotate(0deg);
    transition: 300ms;
    z-index: 30;
  }
}
@media only screen and(min-width:900px) {
  .ham-container {
    display: none;
  }
}
@media only screen and(min-width:500px) {
  button {
    display: none;
  }
}
</style>
