<template>
  <transition name="opa">
    <OtpModal v-if="shopOtp" @close="shopOtp = false" />
  </transition>
  <transition name="opa">
    <ConfirmModal v-if="showConfirm" @close="showConfirm = false" />
  </transition>
  <transition name="opa">
    <SubmitModal
      v-if="showSubmit"
      @close="showSubmit = false"
      @confirm="next"
    />
  </transition>
  <transition name="opa">
    <DisputeModal v-if="disputeModal" @close="disputeModal = false" />
  </transition>
  <div class="container columnd">
    <div class="banner"></div>
    <div class="rowd gap24 content-container w100">
      <Terms style="flex: 1" />
      <Chat
        @otp="shopOtp = true"
        @confirm="showConfirm = true"
        @submit="showSubmit = true"
        @dispute="disputeModal = true"
        style="flex: 1"
      />
    </div>
  </div>
</template>

<script>
import Terms from "./Terms.vue";
import Chat from "./Chat.vue";
import OtpModal from "./OtpModal.vue";
import ConfirmModal from "./ConfirmModal.vue";
import SubmitModal from "./SubmitModal.vue";
import DisputeModal from "./DisputeModal.vue";
export default {
  name: "Negotiation,",
  components: {
    Terms,
    Chat,
    OtpModal,
    ConfirmModal,
    SubmitModal,
    DisputeModal,
  },
  data() {
    return {
      shopOtp: false,
      showConfirm: false,
      showSubmit: false,
      disputeModal: false,
    };
  },
  methods: {
    next() {
      this.showSubmit = false;
      this.shopOtp = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.banner {
  background-image: url("../../../assets/Background/CropCircle.svg");
  background-size: 100%;
  height: 255px;
  background-repeat: no-repeat;
  transform: translateY(-110px);
  width: 100%;
}
.mini-container {
  padding: 32px;
}
.content-container {
  transform: translateY(-90px);
  min-height: 588px;
}
@media only screen and (max-width: 1024px) {
  .content-container {
    flex-direction: column !important;
  }
}
</style>
