<template>
  <div class="mini-container columnd w100 gap32">
    <h2
      v-if="$route.name === 'BuyTimer'"
      class="f22-700 c-lightCryan text-start"
    >
      You made the payment!
    </h2>
    <h2
      v-if="$route.name === 'SellTimer'"
      class="f22-700 c-lightCryan text-start"
    >
      The payment has been made!
    </h2>
    <h2
      v-if="$route.name.includes('Submited')"
      class="f22-700 c-lightCryan text-start"
    >
      Success!
    </h2>
    <div
      v-if="$route.name === 'BuySubmit' || $route.name === 'SellSubmit'"
      class="rowd space-between w100"
    >
      <div class="rowd gap8 align-center">
        <div id="greendot"></div>
        <h1 class="f22-700 c-lightOrange">Trade Started!</h1>
      </div>
      <div class="rowd align-center gap8">
        <inline-svg
          class="fill-lCryan"
          :src="require('../../../assets/Icons/clock.svg')"
        />
        <span class="f14-400 c-ddGrey">13:45</span>
      </div>
    </div>
    <div v-if="$route.name === 'BuySubmit'" class="selectCoin">
      <span class="f12-700 c-darkGrey text-start">Paypal Email Address</span>
      <div class="rowd space-between">
        <span class="f16-400 c-medGrey">marjan.kh@gmail.com</span>
        <inline-svg class="" :src="require('../../../assets/Icons/copy.svg')" />
      </div>
    </div>
    <div class="buysubmit columnd gap24">
      <h3 class="f14-700 c-ddGrey text-start">Please pay 10$ with paypal</h3>
      <p class="f14-400 c-darkGrey text-start">
        PayPal is one of the major online money transfer systems in the world
        that serves as an alternative to checks and money orders. It offers an
        easy and quick means to send and receive funds globally, as well as
        process payments anytime, anywhere.
      </p>
      <p class="f14-400 c-darkGrey text-start">
        Buying Bitcoin with PayPal instantly is now possible on Paxful’s
        peer-to-peer marketplace. You can find several offers that accept PayPal
        as the preferred payment method in our platform. If you don’t find a
        suitable offer, you can always create your own offer to attract users
        who want to trade BTC using PayPal.
      </p>
      <p class="f14-400 c-darkGrey text-start">
        For a more secure trading experience, some sellers may require
        additional steps to confirm the identities of their trading partners.
        These steps might ask you to present certain documents such as ID cards,
        selfies, and/or screenshots. This reduces the risk of fraudulent
        payments and unauthorized usage of PayPal accounts, thereby making
        Paxful a safer marketplace.
      </p>
    </div>
    <div v-if="$route.name === 'SellSubmit'" class="columnd gap24">
      <h3 class="f14-700 c-ddGrey text-start">
        Yoy are selling 0.6563 BTC for 6,474 USD to RayalGaslin
      </h3>
      <p class="f14-400 c-darkGrey text-start">
        PayPal is one of the major online money transfer systems in the world
        that serves as an alternative to checks and money orders. It offers an
        easy and quick means to send and receive funds globally, as well as
        process payments anytime, anywhere.
      </p>
      <p class="f14-400 c-darkGrey text-start">
        For a more secure trading experience, some sellers may require
        additional steps to confirm the identities of their trading partners.
        These steps might ask you to present certain documents such as ID cards,
        selfies, and/or screenshots. This reduces the risk of fraudulent
        payments and unauthorized usage of PayPal accounts, thereby making
        Paxful a safer marketplace.
      </p>
    </div>
    <div class="tag-container columnd gap16">
      <h3
        v-if="$route.name === 'SellSubmit' || $route.name.includes('Timer')"
        class="f14-700 c-ddGrey text-start"
      >
        Tags
      </h3>
      <div
        v-if="$route.name === 'SellSubmit' || $route.name.includes('Timer')"
        class="tags rowd gap8"
      >
        <span class="f12-400 c-darkGrey">No receipt needed</span>
        <span class="f12-400 c-darkGrey">No verification needed</span>
        <span class="f12-400 c-darkGrey">Trusted user</span>
      </div>
    </div>
    <div class="info columnd gap16">
      <h3
        v-if="$route.name === 'SellSubmit' || $route.name === 'SellTimer'"
        class="f14-700 c-ddGrey text-start"
      >
        info
      </h3>
      <p
        v-if="$route.name === 'SellSubmit' || $route.name === 'SellTimer'"
        class="f14-400 c-darkGrey text-start"
      >
        7,7838 ETH plus 0,848 ETH fee in beeing safelu held in Radin scrow
      </p>
    </div>
    <div v-if="$route.name.includes('Submited')" class="columnd gap16">
      <h2 class="f16-700 c-black text-start">
        Please consider Leaving a feedback for Ryan
      </h2>
      <div class="rowd space-between w100 gap24">
        <p class="f16-400 c-darkGrey text-start">
          Please leave a feedback to help the others haveing better trades
        </p>
        <div class="rowd gap32">
          <div class="selectCoin dislike">
            <inline-svg
              class="fill-red rotate-up"
              :src="require('../../../assets/Icons/bilakh.svg')"
            />
          </div>
          <div class="selectCoin like">
            <inline-svg
              class="fill-green"
              :src="require('../../../assets/Icons/bilakh.svg')"
            />
          </div>
        </div>
      </div>
      <textarea name="" id="" cols="30" rows="10"></textarea>
      <button class="secondary-btn f14-700">Submit Feedback</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Terms",
};
</script>

<style lang="scss" scoped>
.secondary-btn {
  max-width: 180px;
  align-self: flex-end;
}
textarea {
  resize: none;
  background-color: var(--llGrey);
  padding: 12px 17.5px;
  border-radius: 8px;
}
.like {
  transition: 500ms;
  &:hover {
    background-color: var(--green);
    svg {
      fill: white;
    }
  }
}
.dislike {
  transition: 500ms;
  &:hover {
    background-color: var(--red);
    svg {
      fill: white;
    }
  }
}
</style>
