<template>
  <div class="notifications mini-container">
    <h1 class="text-start f16-700 c-lightOrange fBold">Notifications</h1>
    <div class="notifs columnd w100 gap8 align-center">
      <div
        v-for="(notif, index) in notifs"
        :key="index"
        @click="notif.show = !notif.show"
        :class="{ active: notif.show }"
        class="notif columnd w100 gap8"
      >
        <div class="rowd w100 align-center space-between">
          <div class="columnd gap4">
            <div class="rowd gap4 align-center">
              <circle></circle>
              <span class="f14-400 c-darkGrey">Order submited</span>
            </div>
            <span class="f12-400 c-medGrey text-start">12June 22:32</span>
          </div>
          <inline-svg
            class="medgrey-svg"
            :src="require('../../../assets/Icons/arrow.svg')"
          />
        </div>
        <p v-if="notif.show" class="f12-400 c-darkGrey text-start">
          Your request will Aprove and pay manually to you. Transaction fee wil
          be counted on withdraw amount
        </p>
      </div>
    </div>
    <a
      class="f12-700 c-darkOrange"
      @click.prevent="$router.push({ name: 'Notifications' })"
      href=""
      >View All Notifications</a
    >
  </div>
</template>

<script>
export default {
  name: "NotificationsNav",
  data() {
    return {
      notifs: [
        {
          show: false,
        },
        {
          show: false,
        },
        {
          show: false,
        },
        {
          show: false,
        },
        {
          show: false,
        },
        {
          show: false,
        },
        {
          show: false,
        },
        {
          show: false,
        },
        {
          show: false,
        },
        {
          show: false,
        },
        {
          show: false,
        },
        {
          show: false,
        },
      ],
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
  mounted() {
    window.addEventListener("click", (e) => {
      if (!e.target.closest(".notifications")) {
        this.close();
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.active {
  background-color: var(--llGrey);
}
.notifs {
  height: 100%;
  overflow: scroll;
}
.notif {
  cursor: pointer;
  padding: 12px;
  border-radius: 12px;
  border-bottom: 1px solid var(--llGrey);
  &:hover {
    background-color: var(--llGrey);
  }
}
circle {
  border-radius: 2px;
  width: 8px;
  height: 8px;
  background-color: var(--lightOrange);
}
.mini-container {
  position: fixed;
  left: 0;
  top: 0;
  border-radius: 0px 24px 24px 0px;
  height: 80%;
  width: 20%;
  min-width: 300px;
  padding: 32px 24px;
  z-index: 50;
  display: flex;
  flex-direction: column;
  gap: 40px;
}
</style>
