<template>
  <div class="mini-container w100 columnd g32">
    <h2 class="f22-700 c-lightOrange w100 text-start">
      How much do you want to
      {{ $route.path.includes("buy") ? "Buy" : "Sell" }}?
    </h2>
    <div class="columnd gap16 w100">
      <div class="columnd gap8 w100">
        <div class="price-inputs rowd space-between w100 gap24">
          <div style="flex: 0.7" class="selectCoin">
            <span class="f12-700 c-darkGrey text-start"> I Will Pay </span>
            <div class="rowd space-between">
              <input
                class="inputa w100"
                type="text"
                placeholder="Enter amount"
              />
              <span class="f16-400 c-medGrey"> USD </span>
            </div>
          </div>
          <div style="flex: 0.7" class="selectCoin">
            <span class="f12-700 c-darkGrey text-start"> And Receive </span>
            <div class="rowd space-between">
              <img
                class="coin24"
                src="../../../assets/test/ETHEREUM.png"
                alt=""
              />
              <input class="inputamount w100" type="text" placeholder="ETH" />
            </div>
          </div>
        </div>
        <p class="f14-400 c-darkGrey text-start">Enter amount to get started</p>
      </div>
      <div class="warning rowd align-center space-between">
        <div class="rowd gap8 align-center">
          <img src="../../../assets/Icons/Warning.svg" alt="" />
          <p class="f12-700 c-ddGrey">We need some additional detailes</p>
          <span class="link12-700 c-darkOrange pointer">
            Start verification
          </span>
        </div>
        <button @click="reD" style="max-width: 200px" class="primary-btn">
          {{ $route.path.includes("buy") ? "Buy" : "Sell" }} Now
        </button>
      </div>
      <div class="tags rowd gap8">
        <span class="f12-400 c-darkGrey"> No receipt needed </span>
        <span class="f12-400 c-darkGrey"> No verification needed </span>
        <span class="f12-400 c-darkGrey"> Trusted user </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Shoping",
  methods: {
    reD() {
      let path;
      if (this.$route.path.includes("buy")) {
        path = "BuySubmit";
      } else {
        path = "SellSubmit";
      }
      this.$router.push({ name: path });
    },
  },
};
</script>

<style lang="scss" scoped>
.inputa {
  background-color: transparent;
  &::placeholder {
    font-size: clamp(14px, 1vw, 16px);
    font-weight: 400;
  }
}
.inputamount {
  background-color: transparent;
  &::placeholder {
    float: right;
    font-size: clamp(14px, 1vw, 16px);
    font-weight: 400;
  }
}
.mini-container {
  padding: 48px;
}
@media only screen and(max-width:650px) {
  .price-inputs,
  .warning {
    flex-direction: column !important;
  }
}
</style>
