<template>
  <div class="offertable columnd gap12">
    <div class="rowd w100 space-between">
      <h1 class="c-lightOrange f22-700">
        {{ $route.path.includes("buy") ? "Buy Bitcoin" : "Sell Bitcoin" }}
      </h1>
      <div class="rowd gap8">
        <img src="../../../assets/Icons/filter.svg" alt="" />
        <div class="selectCoin rowd gap8">
          <input type="checkbox" name="" id="" />
          <span class="f12-400 c-darkGrey">Vonders active in 10min</span>
        </div>
      </div>
    </div>
    <div class="table-container w100 columnd gap12">
      <div class="header rowd">
        <span style="flex: 1.8" class="f12-700 c-darkGrey text-start"
          >Sell To
        </span>
        <span style="flex: 1" class="f12-700 c-darkGrey text-start"
          >Get Paid With
        </span>
        <span style="flex: 1.2" class="f12-700 c-darkGrey"> Rate</span>
        <span style="flex: 1.5" class="f12-700 c-darkGrey"
          >Avg.Tarde Speed
        </span>
        <span style="flex: 1" class="f12-700 c-darkGrey text-end"
          >Rate Per BTC
        </span>
      </div>
      <div class="body-container columnd gap12">
        <div v-for="i in 10" :key="i" class="body rowd gap12 w100">
          <div style="flex: 1.8" class="columnd gap12">
            <div class="rowd gap8">
              <img
                class="img48"
                src="../../../assets/test/fakeprofile.svg"
                alt=""
              />
              <div class="columnd gap12">
                <span class="f16-700 c-ddGrey"> Mohammad Al Jaber </span>
                <div class="isonline">
                  <span class="circle on"></span>
                  <span class="f14-700 c-black"> online </span>
                </div>
              </div>
            </div>
            <div class="tags gap8 align-start">
              <span class="f12-400 c-darkGrey">Bank Transfer</span>
              <span class="f12-400 c-darkGrey">PayPall</span>
              <span class="f12-400 c-darkGrey">Skrill</span>
            </div>
          </div>
          <div style="flex: 1" class="columnd gap4">
            <span class="f14-400 c-darkGrey text-start">PayPal</span>
            <span class="f14-400 c-medGrey text-start">Fast Payment</span>
          </div>
          <div style="flex: 1.2" class="rowd gap12 justify-center">
            <div class="rowd gap4">
              <inline-svg
                class="fill-red rotate-up"
                :src="require('../../../assets/Icons/bilakh.svg')"
              />
              <span>3</span>
            </div>
            <div class="rowd gap4">
              <inline-svg
                class="fill-green"
                :src="require('../../../assets/Icons/bilakh.svg')"
              />
              <span>127</span>
            </div>
          </div>
          <div style="flex: 1.5" class="columnd gap12">
            <span class="f14-400 c-darkGrey">There is no trade</span>
            <div class="columnd gap4">
              <span class="f12-700 c-darkGrey">Limit</span>
              <span class="f14-400 c-medGrey">2,000 - 15,000 USD</span>
            </div>
          </div>
          <div style="flex: 1" class="columnd gap12 align-end">
            <h2 class="f18-700 c-darkGrey">2.4 EUR</h2>
            <div class="rowd gap8">
              <inline-svg
                class="fill-green"
                :src="require('../../../assets/Icons/ArrowUp.svg')"
              />
              <span class="c-green f14-700">1.2%</span>
            </div>
            <button class="secondary-btn">
              {{ $route.path.includes("buy") ? "Buy" : "Sell" }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Table",
};
</script>

<style lang="scss" scoped>
.offertable {
  overflow: scroll;
}
.table-container {
  min-width: 700px;
}
.body-container {
  max-height: 70vh;
  overflow: scroll;
}
.secondary-btn {
  max-width: 80px !important;
  min-width: unset !important;
}
.body {
  border-radius: 8px;
  padding: 12px 8px;
  border-bottom: 2px solid var(--lightGrey);
  transition: 300ms;
  .tags {
    span {
      transition: 300ms;
    }
  }
  &:hover {
    background-color: var(--lightGrey);
    .secondary-btn {
      background-color: var(--lightOrange);
      color: white;
    }
    .tags {
      span {
        background-color: white;
      }
    }
  }
}
.header {
  border-radius: 24px;
  padding: 12px 8px;
  border-bottom: 2px solid var(--lightGrey);
}
.selectCoin {
  background-color: var(--lightGrey) !important;
}
</style>
