<template>
  <div class="mini-container columnd gap32">
    <h2 class="f18-700 c-darkBlue text-start">
      About this {{ $route.path.includes("buy") ? "Seller" : "Buyer" }}
    </h2>
    <div class="profile-info columnd gap8">
      <div @click="$router.push({ name: 'Profile' })" class="rowd gap8 pointer">
        <img class="img80" src="../../../assets/test/fakeprofile.svg" alt="" />
        <div class="columnd gap12">
          <span class="f16-700 c-ddGrey"> Mohammad Al Jaber </span>
          <div class="isonline">
            <span class="circle on"></span>
            <span class="f14-700 c-black"> online </span>
          </div>
        </div>
      </div>
      <div class="feedbacks rowd gap32">
        <div class="columnd gap16">
          <span class="f14-400 c-ddGrey">Positive feedback</span>
          <div class="rowd gap8">
            <inline-svg
              class="fill-green"
              :src="require('../../../assets/Icons/bilakh.svg')"
            />
            <span class="f18-700 c-black">477</span>
          </div>
        </div>
        <div class="columnd gap16">
          <span class="f14-400 c-ddGrey">Negative feedback</span>
          <div class="rowd gap8">
            <inline-svg
              class="fill-red rotate-up"
              :src="require('../../../assets/Icons/bilakh.svg')"
            />
            <span class="f18-700 c-black">34</span>
          </div>
        </div>
      </div>
    </div>
    <div class="columnd w100 gap24">
      <div class="rowd space-between">
        <div style="flex: 1.5" class="rowd gap12 align-center">
          <inline-svg
            class="fill-black"
            :src="require('../../../assets/Icons/tik.svg')"
          />
          <div class="rowd gap4">
            <inline-svg :src="require('../../../assets/Icons/idcard.svg')" />
            <span>ID verified</span>
          </div>
        </div>
        <div style="flex: 1.5" class="rowd gap12 align-center">
          <inline-svg
            class="fill-black"
            :src="require('../../../assets/Icons/tik.svg')"
          />
          <div class="rowd gap4">
            <inline-svg :src="require('../../../assets/Icons/location.svg')" />
            <span>Address verified</span>
          </div>
        </div>
        <div style="flex: 1" class="fakerow"></div>
      </div>
      <div class="rowd space-between">
        <div style="flex: 1.5" class="rowd gap12 align-center">
          <inline-svg
            class="fill-black"
            :src="require('../../../assets/Icons/tik.svg')"
          />
          <div class="rowd gap4">
            <inline-svg
              style="width: 18px; height: 18px"
              class="fill-darkGrey"
              :src="require('../../../assets/Icons/email.svg')"
            />
            <span>Email verified</span>
          </div>
        </div>
        <div style="flex: 1.5" class="rowd gap12 align-center">
          <inline-svg
            class="fill-black"
            :src="require('../../../assets/Icons/tik.svg')"
          />
          <div class="rowd gap4">
            <inline-svg :src="require('../../../assets/Icons/mobile.svg')" />
            <span>Phone verified</span>
          </div>
        </div>
        <div style="flex: 1" class="fakerow"></div>
      </div>
    </div>
    <div class="rowd space-between">
      <span class="f14-400 c-black">Average trade speed</span>
      <span class="f16-700 c-black">5h</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "PersonInfo",
};
</script>

<style lang="scss" scoped>
.mini-container {
  padding: 48px;
}
</style>
