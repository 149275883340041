<template>
  <div id="navbar" class="navbar container">
    <div class="item rowd align-center">
      <HamberMenu :links="links" />
      <img
        @click="$router.push('/')"
        class="logo"
        src="../../../assets/Logo.svg"
        alt="logo"
      />
      <div class="item rowd row-items">
        <a
          v-for="(item, index) in links"
          :key="index"
          @click.prevent="$router.push('/' + item.address)"
          :href="item.address"
          class="primary-a f14-400"
        >
          {{ item.name }}
        </a>
      </div>
    </div>
    <div v-if="!$store.state.token" class="btns rowd">
      <button
        @click.prevent="$router.push('/register')"
        class="secondary-btn f14-700"
      >
        Register
      </button>
      <button
        @click.prevent="$router.push('/login')"
        class="primary-btn f14-700"
      >
        Login
      </button>
    </div>
    <LogedInParts v-if="$store.state.token" />
  </div>
</template>

<script>
import HamberMenu from "./HamberMenu.vue";
import LogedInParts from "./LogedInParts.vue";
export default {
  components: { HamberMenu, LogedInParts },
  name: "Navbar",
  data() {
    return {
      links: [
        {
          name: "Buy/Sell",
          address: "findoffersell",
        },
        {
          name: "Creating Posting",
          address: "createoffer",
        },
        {
          name: "Wallet",
          address: "panel/wallet",
        },
        {
          name: "Contact Us",
          address: "contactus",
        },
        {
          name: "About Us",
          address: "aboutus",
        },
        {
          name: "FAQ",
          address: "faq",
        },
      ],
    };
  },
  methods: {
    changeBack() {},
  },
  mounted() {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 40) {
        document.getElementById("navbar").classList.add("background");
      }
      if (window.scrollY < 40) {
        document.getElementById("navbar").classList.remove("background");
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.background {
  background: var(--llGrey) !important;
}
.navbar {
  transition: 500ms;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 80;
}
.item {
  gap: 24px;
  position: relative;
}
.logo {
  cursor: pointer;
}
.btns {
  gap: 8px;
}
.normal {
  max-width: 100px;
}
@media only screen and(max-width:900px) {
  .item .item {
    display: none;
  }
}
@media only screen and(max-width:500px) {
  .btns {
    display: none;
  }
}
</style>
