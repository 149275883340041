<template>
  <div class="modal-backdrop" @click.self="close">
    <div class="modal">
      <inline-svg
        class="fill-black align-self-end svg14"
        @click="close"
        :src="require('../../../assets/Icons/close.svg')"
      />
      <div class="columnd gap32 w100">
        <h2>Didn’t get your Cryptocurrency?</h2>
        <p>
          If you didn’t get your crypto currency let us know. in you have extera
          detailes about this trade please type it down.
        </p>
        <textarea
          name=""
          id=""
          cols="10"
          rows="10"
          placeholder="Write Here"
        ></textarea>
        <button @click.prevent="close" class="primary-btn">Dispute</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "disputeModal",
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
textarea {
  resize: none;
  background-color: var(--llGrey);
  padding: 12px 17.5px;
  border-radius: 8px;
}
.primary-btn {
  align-self: flex-end;
}
</style>
