<template>
  <div class="rowd space-betweeen container gap24">
    <Filters style="flex: 1" />
    <Table style="flex: 2.9" />
  </div>
</template>

<script>
import Filters from "./Filters.vue";
import Table from "./Table.vue";
export default {
  name: "FindOfferComp",
  components: {
    Filters,
    Table,
  },
};
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 1100px) {
  .container {
    flex-direction: column !important;
    align-items: center;
    justify-content: center;
    padding: 80px 24px !important;
    overflow: scroll;
    .offertable {
      width: 100%;
    }
    .mini-container {
      width: 100%;
    }
  }
}
.container {
  padding: 80px 80px 24px 0;
}
</style>
