<template>
  <div class="modal-backdrop" @click.self="close">
    <div class="modal">
      <inline-svg
        class="fill-black align-self-end svg14"
        @click="close"
        :src="require('../../../assets/Icons/close.svg')"
      />
      <div class="columnd gap32 w100">
        <h2>You are releasing your ETH!</h2>
        <p>
          you are about to send <span class="c-black">0,88362BTC</span> to the
          buyers wallet.
        </p>
        <div class="warning rowd gap12 align-center">
          <inline-svg
            class="fill-black"
            :src="require('../../../assets/Icons/Warning.svg')"
          />
          <h4 class="f12-700 c-ddGrey text-start">
            If you have not received your payment click
            <a class="link12-700 c-darkBlue" @click.prevent="close" href=""
              >Cancel</a
            >
            and return to chat.
          </h4>
        </div>
        <div class="rowd justify-end gap12">
          <button @click.prevent="close" class="normal c-darkGrey f14-700">
            Cancel
          </button>
          <button @click.prevent="confirm" class="primary-btn">
            Release ETH
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SubmitModal",
  methods: {
    close() {
      this.$emit("close");
    },
    confirm() {
      this.$emit("confirm");
    },
  },
};
</script>

<style lang="scss" scoped>
.normal {
  background-color: transparent !important;
  padding: 12px 24px;
  &:hover {
    color: var(--darkCryan) !important;
  }
}
</style>
