import "./assets/Style/Main.scss";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router/Index";
import store from "./store";
import InlineSvg from "vue-inline-svg";
import toFuncs from "./Helpers/ToFuncs";

const app = createApp(App);

app.use(store).use(router).component("inline-svg", InlineSvg).mount("#app");

app.config.globalProperties.$toFuncs = toFuncs;
