<template>
  <div class="modal-backdrop" @click.self="close">
    <div class="modal">
      <inline-svg
        class="fill-black align-self-end svg14"
        @click="close"
        :src="require('../../../assets/Icons/close.svg')"
      />
      <div class="columnd gap32 w100">
        <div class="columnd gap16">
          <h2>We’ve send a verifivation code to your Email!</h2>
          <p>Please write 6 digit code in here</p>
          <p>
            you are about to send <span class="c-black">0,88362BTC</span> to the
            buyers wallet.
          </p>
        </div>
        <CodeInput class="w100" />
        <span class="f14-700 c-darkGrey align-self-center"> 12:26 </span>
        <button
          @click.prevent="reD"
          class="primary-btn align-self-end justify-center"
        >
          submit
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import CodeInput from "../../Tools/CodeInput.vue";
export default {
  name: "OtpModal",
  components: {
    CodeInput,
  },
  methods: {
    close() {
      this.$emit("close");
    },
    reD() {
      this.$router.push("SellSubmited");
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped></style>
