<template>
  <div class="mini-container columnd space-between">
    <div class="rowd w100 space-between">
      <div id="profil-container" class="rowd gap8">
        <img class="img48" src="../../../assets/test/fakeprofile.svg" alt="" />
        <div class="columnd gap8">
          <span class="f14-700 c-ddGrey">Ryan Gaslin</span>
          <div class="isonline">
            <span class="circle on"></span>
            <span class="f14-700 c-black"> online </span>
          </div>
        </div>
      </div>
      <div class="btns rowd gap24">
        <button class="normal f12-700 c-medGrey">cancel</button>
        <button
          @click="confirm"
          v-if="$route.name === 'BuySubmit'"
          class="primary-btn"
        >
          Paid
        </button>
        <button
          v-if="
            $route.name.includes('Sell') && !$route.name.includes('Submited')
          "
          @click="submit"
          class="primary-btn"
        >
          Release Etherium
        </button>
        <button
          v-if="
            $route.name === 'BuyTimer' ||
            $route.name === 'BuySubmited' ||
            $route.name === 'SellSubmited'
          "
          @click="disputeModal"
          class="primary-btn"
        >
          {{ $route.name === "BuyTimer" ? "12:22  Dispute" : "Dispute" }}
        </button>
      </div>
    </div>
    <div id="chat" class="w100">
      <div class="pm receiver">
        <p class="pmp">Hi! Please send me the receipt</p>
        <span class="time">22:34am</span>
      </div>
      <div class="pm sender">
        <p class="pmp">Hi! Please send me the receipt</p>
        <span class="time">22:34am</span>
      </div>
      <div class="pm sender">
        <p class="pmp">Hi! Please send me the receipt</p>
        <span class="time">22:34am</span>
      </div>
    </div>
    <div class="selectCoin rowd w100 gap24 align-end">
      <input placeholder="Type Here..." class="w100" type="text" />
      <inline-svg :src="require('../../../assets/Icons/sendDoc.svg')" />
      <inline-svg :src="require('../../../assets/Icons/send.svg')" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Terms",
  methods: {
    otp() {
      this.$emit("otp");
    },
    confirm() {
      this.$emit("confirm");
    },
    disputeModal() {
      this.$emit("dispute");
    },
    submit() {
      if (this.$route.name.includes("Submit")) {
        this.$route.name.includes("Buy")
          ? this.$router.push({ name: "BuyTimer" })
          : this.$router.push({ name: "SellTimer" });
      } else {
        this.$emit("submit");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
input {
  background: none !important;
}
.normal {
  background: none;
}
button {
  max-height: 40px;
}
</style>
