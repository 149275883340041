<template>
  <div class="navbar-icons rowd">
    <transition name="notifs">
      <Notifications v-if="notifs" @close="notifs = false" />
    </transition>
    <inline-svg
      @click="$router.push({ name: 'Chats' })"
      class="normal-svg"
      :src="require('../../../assets/Icons/chat.svg')"
    />
    <inline-svg
      @click="notifs = !notifs"
      class="normal-svg notifications"
      :src="require('../../../assets/Icons/notification.svg')"
    />
    <inline-svg
      class="normal-svg profile-svg"
      :src="require('../../../assets/Icons/profile.svg')"
    />
    <div @click="drop = !drop" class="avatar rowd align-center pointer">
      <span class="userName">
        {{ $store.state.userInfo.firstName + $store.state.userInfo.lastName }}
      </span>
      <inline-svg
        class="normal-svg"
        :class="{ 'rotate-up': drop }"
        :src="require('../../../assets/Icons/arrow.svg')"
      />
    </div>
    <transition name="navdrup">
      <DropDown @close="drop = false" v-if="drop" />
    </transition>
  </div>
</template>

<script>
import DropDown from "./DropDown.vue";
import Notifications from "./Notifications.vue";
export default {
  components: { DropDown, Notifications },
  name: "LogedInParts",
  data() {
    return {
      drop: false,
      notifs: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.avatar {
  gap: 8px;
  span:hover {
    color: var(--darkCryan);
  }
}
.navbar-icons {
  gap: 24px;
  // position: relative;
}
@media only screen and(max-width:500px) {
  .userName {
    display: none;
  }
}
@media only screen and(max-width:350px) {
  .profile-svg {
    display: none;
  }
}
</style>
