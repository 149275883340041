export default {
  p2e(string) {
    return string.toString().replace(/[۰-۹]/g, (d) => "۰۱۲۳۴۵۶۷۸۹".indexOf(d));
  },
  toLocal(number, decimal = 8) {
    //purify number
    number = number + "";
    if (number === ".") {
      if (decimal) return "0.";
      else return "";
    }

    if (number.startsWith("-")) return 0;

    if (number.toLowerCase().includes("e")) {
      if (parseFloat(number) < 1.0e-6) {
        //zero numbers
        return 0;
      } else number = parseFloat(number) + "";
    }
    console.log(this);
    number = this.p2e(number).replace(/[^\d.]|\.{2}|,/gi, "");
    if (number.includes(".")) {
      number = number.split(".")[0] + "." + (number.split(".")[1] || "");
    }

    if (parseFloat(number)) {
      //separate number
      const int = parseFloat(number.split(".")[0]).toLocaleString("en");
      if (number.includes(".") && decimal) {
        return int + "." + (number.split(".")[1].slice(0, decimal) || "");
      } else {
        return int;
      }
    } else {
      return number;
    }
  },
};
