<template>
  <div class="mini-container w100 columnd gap32">
    <h2 class="f18-700 c-lightOrange text-start">Offer Terms</h2>
    <p class="f14-400 c-darkGrey text-start w100">
      I have a European address, U.S address and a Canadian address. Please let
      me know where you prefer sending to! <br /><br />
      ==================================<br /><br />
      Please open trade and choose the amount. Afterward, I will provide an
      address and name you can send the cash to. After I have received the cash
      I will release the BTC to you. Absolutely, no third parties! If a third
      party sent a package for the purpose of this trade, we will attempt to
      contact the sender to explain the reason for returning it.<br /><br />
      When I receive your package, I will take a video as I open the package,
      count the cash, and check for counterfeit bills. I will then release the
      BTC to you.<br /><br />

      =========================================================<br /><br />
      Larger amounts use Priority Express. Small amounts, anything with
      tracking.<br /><br />
      You may request Starting price or Release Price but you must decide at the
      start of the trade.<br /><br />
      Package the cash securely and discretely to avoid loss or theft while it
      is in transit. An envelope with some printer paper wrapped around the cash
      works. then place that envelope into a Priority Express or Bubble mailer
      envelope. Send as personal documents for customs.<br /><br />
      **Please also check out my other ads for other convenient methods!
    </p>
  </div>
</template>

<script>
export default {
  name: "Terms",
};
</script>

<style lang="scss" scoped>
.mini-container {
  padding: 48px;
}
p{
  overflow: hidden;
}
</style>
