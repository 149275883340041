<template>
  <div class="container columnd gap32">
    <div class="banner w100">
      <h1 class="f36-700 c-darkBlue">
        {{ $route.path.includes("buy") ? "BUY" : "SELL" }} Etherium
      </h1>
    </div>
    <Shoping />
    <div class="info-container rowd gap24 w100 wrap">
      <OfferInfo style="flex: 1" />
      <PersonInfo style="flex: 1" />
    </div>
    <Terms />
    <button
      style="max-width: 200px"
      class="secondary-btn align-center justify-center"
    >
      <inline-svg :src="require('../../../assets/Icons/Problem.svg')" />
      Report a problem
    </button>
  </div>
</template>

<script>
import Shoping from "./Shoping.vue";
import OfferInfo from "./OfferInfo.vue";
import PersonInfo from "./PersonInfo.vue";
import Terms from "./Terms.vue";
export default {
  name: "ShopFrom",
  components: {
    Shoping,
    OfferInfo,
    PersonInfo,
    Terms,
  },
};
</script>

<style lang="scss" scoped>
.banner {
  background-image: url("../../../assets/Background/CropCircle.svg");
  background-size: 100%;
  height: 255px;
  background-repeat: no-repeat;
  padding-top: 80px;
}
.container {
  padding-top: 0 !important;
}
@media only screen and(max-width:1200px) {
  .info-container {
    flex-direction: column !important;
  }
}
</style>
