<template>
  <Navbar v-if="hide" />
  <router-view />
  <Footer v-if="hide" />
</template>

<script>
import Navbar from "./components/PublicComponents/Navbar/Navbar.vue";
import Footer from "./components/PublicComponents/Footer.vue";
export default {
  name: "App",
  components: {
    Navbar,
    Footer,
  },
  computed: {
    hide() {
      return (
        this.$route.name !== "Register" &&
        this.$route.name !== "Login" &&
        !this.$route.path.includes("otp")
      );
    },
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
