<template>
  <div class="footer rowd space-between">
    <div class="copyright columnd w100">
      <div class="content columnd space-between">
        <div class="company-container rowd space-between h100">
          <div class="company-info columnd space-between">
            <div class="logo columnd">
              <img src="../../assets/Logo.svg" alt="" />
              <p class="f14-400 c-ddGrey text-start">
                Radin, A trading platfom for buy and sell cryptocurrency, in
                safe and clear environment.
              </p>
            </div>
            <div class="social rowd">
              <inline-svg class="fill-black" :src="require('../../assets/Icons/facebook.svg')" />
              <inline-svg :src="require('../../assets/Icons/instagram.svg')" />
              <inline-svg class="fill-black" :src="require('../../assets/Icons/twitter.svg')" />
              <inline-svg :src="require('../../assets/Icons/whatsApp.svg')" />
            </div>
          </div>
          <div class="company-links rowd justify-center w100">
            <div class="list columnd">
              <span class="title c-darkBlue f14-700 text-start"> Radin </span>
              <div class="items columnd">
                <a @click.prevent="" class="primary-a f14-400 text-start">
                  About Radin
                </a>
                <a @click.prevent="" class="primary-a f14-400 text-start">
                  Contact Radin
                </a>
                <a @click.prevent="" class="primary-a f14-400 text-start">
                  FAQ
                </a>
                <a @click.prevent="" class="primary-a f14-400 text-start">
                  Blog
                </a>
              </div>
            </div>
            <div class="list columnd">
              <span class="title c-darkBlue f14-700 text-start"> Radin </span>
              <div class="items columnd">
                <a @click.prevent="" class="primary-a f14-400 text-start">
                  About Radin
                </a>
                <a @click.prevent="" class="primary-a f14-400 text-start">
                  Contact Radin
                </a>
                <a @click.prevent="" class="primary-a f14-400 text-start">
                  FAQ
                </a>
                <a @click.prevent="" class="primary-a f14-400 text-start">
                  Blog
                </a>
              </div>
            </div>
            <div class="list columnd">
              <span class="title c-darkBlue f14-700 text-start"> Radin </span>
              <div class="items columnd">
                <a @click.prevent="" class="primary-a f14-400 text-start">
                  About Radin
                </a>
                <a @click.prevent="" class="primary-a f14-400 text-start">
                  Contact Radin
                </a>
                <a @click.prevent="" class="primary-a f14-400 text-start">
                  FAQ
                </a>
                <a @click.prevent="" class="primary-a f14-400 text-start">
                  Blog
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="underline"></div>
      </div>
      <p class="f12-400 c-ddGrey text-start">
        “Radin” is a registered trademark of Paxful, Inc. Copyright © 2022
        Radin, Inc. All Rights Reserved.
      </p>
    </div>
    <img class="logo-fake" src="../../assets/Footerlogo.svg" alt="" />
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style lang="scss" scoped>
.company-links {
  padding-top: 20px;
  gap: 150px;
}
.items {
  gap: 12px;
}
.list {
  gap: 20px;
}
.social {
  gap: 30px;
  svg {
    cursor: pointer;
  }
}
.logo {
  max-width: 300px;
  gap: 23px;
  img {
    width: 195px;
    height: 56px;
  }
}
.footer {
  background: url("../../assets/Background/Footer.svg");
  height: 368px;
  background-repeat: no-repeat;
  background-size: cover;
}
.copyright {
  padding: 24px 120px;
  gap: 24px;
  height: 100%;
}
.content {
  height: 100%;
  gap: 18px;
  .underline {
    width: 100%;
    height: 1px;
    background: var(--darkCryan);
    border-radius: 8px;
  }
}
@media only screen and(max-width:1200px) {
  .logo-fake {
    display: none;
  }
  .company-links {
    gap: 10px;
    flex-wrap: wrap;
  }
  .company-container {
    gap: 30px;
    flex-wrap: wrap;
  }
  .company-info {
    gap: 20px;
  }
  .footer {
    height: unset !important;
    background: var(--lightGrey);
  }
  .copyright {
    padding: 10px;
  }
}
</style>
