<template>
  <div class="navbar-drop-down">
    <!-- v-for -->
    <div
      @click="reD(item.address)"
      v-for="(item, index) in items"
      :key="index"
      class="item"
    >
      <inline-svg class="normal-svg" :src="item.image" />
      <span class="f16-400"> {{ item.name }} </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavbarDropDown",
  data() {
    return {
      listener: null,
      items: [
        {
          name: "My Profile",
          address: "Profile",
          image: require("../../../assets/Icons/profile.svg"),
        },
        {
          name: "Dashboard",
          address: "Dashboard",
          image: require("../../../assets/Icons/dashboard.svg"),
        },
        {
          name: "Wallet",
          address: "Wallet",
          image: require("../../../assets/Icons/wallet.svg"),
        },
        {
          name: "Settings",
          address: "Settings",
          image: require("../../../assets/Icons/setting.svg"),
        },
        {
          name: "History",
          address: "History",
          image: require("../../../assets/Icons/history.svg"),
        },
        {
          name: "LogOut",
          address: "LogOut",
          image: require("../../../assets/Icons/logout.svg"),
        },
      ],
    };
  },
  methods: {
    reD(val) {
      this.$router.push({ name: val });
    },
    clickOut(e) {
      if (!e.target.closest(".drop-down,.avatar")) {
        this.$emit("close", false);
      }
    },
  },
  beforeUnmount() {
    window.removeEventListener("click", this.clickOut);
  },
  created() {
    window.addEventListener("click", this.clickOut);
  },
};
</script>

<style lang="scss" scoped>
.drop-down {
  right: 70px;
  top: 70px;
  width: 180px;
}
@media only screen and(max-width:450px) {
  .drop-down {
    right: 20px;
  }
}
</style>
